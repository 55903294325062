import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Third from "../assets/media/relations.png";
import Leader from "../assets/media/leader.png";
import Freelace from "../assets/media/freelance-work.png";

function Features() {

  return (
    <Container >


      <div className="meet-team back-img">
    <h2>Our Method!</h2>
    <p>Our not-so-secret sauce
    </p>
    <div className="choose-box">
     
        <div className="box">
         
          <h1><span>1</span>Singular Focus</h1>
          <p>Target a specific ideal customer persona (ICP) to solve a clear, real problem with a simple solution. Focus on one effective marketing or sales channel to achieve product-market fit and generate ₹1 crore in annual recurring revenue (ARR) before expanding further.</p>
       
        </div>
        <div className="box">
       
          <h1><span>2</span>Sell First, Build Second</h1>
          <p>Validate the idea by securing preliminary sales before writing a single line of code. Confirm real market demand before investing money.
          </p>
        
        </div>
        <div className="box">
     
        <h1><span>3</span>Strategy Over Experimentation</h1>
          <p>Experimentation is a tactic, not a strategy. It doesn't replace conviction and common sense. Have a clear and compelling strategy and a roadmap.
          </p>
         
        </div>
        <div className="box">
       
          <h1><span>4</span>Get Profitable ASAP
          </h1>
          <p>Revenue is the only real traction and validation metric, and profitability is the only real scenario for long-term sustainability. Plan for eventual ROI.
          </p>
        
        </div>

        <div className="box">
       
          <h1><span>5</span>Build a Calm Company

          </h1>
          <p>If you want drama, go build a startup and raise venture capital. But if you want a sustainable, profitable, niche B2B SaaS platform, you’re in the right place.
          </p>
        
        </div>


        <div className="box">
       
       <h1><span>6</span>Make Your Software Good


       </h1>
       <p>Even though software is now a commodity, the standards are high. Solid, reliable, user-friendly software won't give a competitive advantage by itself, but it's a necessary foundation for success.
       </p>
     
     </div>

    </div>
</div>







    </Container>
  );
}

export default Features;
