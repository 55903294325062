import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import axios from 'axios';
import Brand from "../assets/media/brand.svg";

function Clients() {
  const [shots, setShots] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    fetchShots();
  }, [page]);

  const fetchShots = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`https://api.dribbble.com/v2/user/shots?access_token=047ae13924e9bd9951bca1a689194a418a4cae0841028572aaf826c506cb7fee&page=${page}&per_page=40`);
      if (response.data.length > 0) {
        setShots(prevShots => [...prevShots, ...response.data]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching shots:', error);
    }
    setLoading(false);
  };

  const handleSeeMore = () => {
    if (hasMore) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <Container>
      <div className="who">
        <div className="rl-child">
          <h4>~Our Portfolio</h4>
        </div>
      </div>
      <div className="shots-container">
        {shots.map(shot => (
          <div key={shot.id} className="shot">
            <a href={shot.html_url} target="_blank" rel="noopener noreferrer">
              <img src={shot.images.normal} alt={shot.title} />
              <p>{shot.title}</p>
              <div className="tags">
                {shot.tags.map(tag => (
                  <span key={tag}>#{tag}</span>
                ))}
              </div>
            </a>
          </div>
        ))}
      </div>
      {hasMore && (
        <button onClick={handleSeeMore} disabled={loading} id="see">
          {loading ? 'Loading...' : 'See More'}
        </button>
      )}
    </Container>
  );
}

export default Clients;
