import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Product from "../assets/media/product.svg";
import FS from "../assets/media/fs.svg";
import VS from "../assets/media/versatile.svg";
import MM from "../assets/media/mm.svg";
import EHB from "../assets/media/ehv.svg";
import EE from "../assets/media/ee.svg";
import EB from "../assets/media/eb.svg";
import ImpactEco from "../assets/media/im.svg";
import Multigrad from "../assets/media/mult.svg";
import YelloMonkey from "../assets/media/yello.webp";
import Texcyle from "../assets/media/texcyle.jpeg";
function Slider() {

  return (
    <Container>
      <div className="mgTagline">
        <div className="tagLeft mobile">
          <img id="product" src={Product} />
          <h2>
          Building best SaaS products.
          </h2>
          <div className="app">
            
           
          <div className="playstore down">
          <a href="contact-us"> <button>+ GET IN TOUCH</button> </a>
          <a href="refer-and-earn"> <button id="light">Refer & Earn</button></a>
      </div>
     
           
          <p>TURN YOUR <span id="border">IDEA</span> INTO A <span id="border">Product</span>  IN JUST 45 Days.</p>

  
      
          </div>

      
     
        </div>

      
      </div>



    </Container>
  );
}

export default Slider;
