import Header from "./screens/common/header";
import Footer from "./screens/common/footer";
import "./assets/css/App.css";
import "./assets/css/Rl.css";
import About from "./screens/Pages/About";
import NotFound from "./screens/Pages/NotFound";
import Homescreen from "./screens/Pages/homescreen";
import { Routes, Route, useLocation } from "react-router-dom";

import Portfolio from "./screens/Pages/Portfolio";
import React, { useEffect } from "react";
import ReactGA from 'react-ga';
import Contact from "./screens/Pages/Contactus";
import Referrals from "./screens/Pages/Referalls";
import MarketPlace from "./screens/Pages/MarketPlace";
import Login from "./screens/Pages/Login";
import Dashboard from "./screens/Pages/Dashboard/Index.jsx";
import Projects from "./screens/Pages/Dashboard/Projects.jsx";
import Billing from "./screens/Pages/Dashboard/Billing.jsx";
import Settings from "./screens/Pages/Dashboard/Settings.jsx";
import ProjectDetail from "./screens/Pages/Dashboard/DetailedProject.jsx";
import Clients from "./components/Clients.js";
import ClientComp from "./screens/Pages/Dashboard/Clients.jsx";
import Calendar from  "./screens/Pages/Dashboard/Calendar.jsx";
import SlackMessagingComponent from "./screens/Pages/Dashboard/Slack.jsx";
import ClientProjects from "./screens/Pages/Dashboard/ClientProjects.js";
import TeamTree from "./screens/Pages/Dashboard/TeamTree.jsx";
import Reports from "./screens/Pages/Dashboard/Reports.jsx";
import ClientDetails from "./screens/Pages/Dashboard/DetailedClient.jsx";
import Career from "./screens/Pages/Career.js";
ReactGA.initialize('G-NYYHJSQ3T6');

function App() {

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/projects" element={<Projects />} />
        <Route path="/dashboard/clients" element={<ClientComp />} />
        <Route path="/dashboard/clients/projects/:id" element={<ClientProjects />} />
        <Route path="/dashboard/projects/:name" element={<ProjectDetail />} />
        <Route path="/dashboard/billings" element={<Billing />} />
        <Route path="/dashboard/calendar" element={<Calendar />} />
        <Route path="/dashboard/chat" element={<SlackMessagingComponent />} />
        <Route path="/dashboard/settings" element={<Settings />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/marketplace" element={<MarketPlace />} />
        <Route path="/*" element={<NotFound />} />
        <Route path="/refer-and-earn" element={<Referrals />} />
        <Route path="/team" element={<TeamTree />} />
        <Route path="/career" element={<Career />} />
        <Route path="/dashboard/reports" element={<Reports />} />
        <Route path="/dashboard/clients/view/:id" element={<ClientDetails />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
