import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";

function Focus() {

  return (
    <Container>
   
  

   <div className="meet-team" id="community">
  <h2 id="center">Want to build a product with us?</h2>
  <div className="connect meet">
  <a href="/contact-us" target="_blank">Let's Talk</a>
  </div>
 
</div>



        <div className="meet-team" id="communty">
        <h2>How our timeline works? <span id="notePri">Terms & Condition Included!</span></h2>
        <div className="testino">
          <ol className="why how">
            
          <li><span>Step 0</span> Requirement and planning.</li>
          <li><span>Step 1</span> Shaping & Development Phase. </li>
          <li><span>Step 2</span> Testing and quality assurance. </li>
          <li><span>Step 3</span> Your feedback and revisions.</li>
          <li id="final"><span>Step 4</span> Final product in the market.</li>
          
         </ol>
        </div>
        </div>

        <div className="meet-team" id="communty">
        <h2>Why RapydLaunch?</h2>
        <div className="testino">
          <ol className="why">
           <li>Speedy Development: Our primary goal is to deliver a fully functional product within an unprecedented timeframe of 45 days, ensuring a rapid go-to-market strategy.</li>
           <li>Efficient Resource Utilization: By providing a dedicated team, we ensure that your project receives focused attention, maximizing productivity and minimizing delays.</li>
           <li>Reduced Time-to-Market: Accelerating the development process translates to quicker market entry, allowing you to stay ahead of competitors and capture early market share.</li>
           <li>No Need for Tech Hiring: Save the hassle of recruiting and managing an in-house tech team. We offer a dedicated team of professionals, reducing the burden on your HR department and allowing you to focus on core business activities.</li> 
          <li>Exclusive Referral Benefits: Enjoy an additional incentive with a 5% discount on our services for every successful referral, fostering a collaborative community while providing cost savings for you and your network.</li> 
          
         </ol>
        </div>
        </div>
    




    </Container>
  );
}

export default Focus;
