import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { Navbar, Dropdown } from "react-bootstrap";
import Logo from "../../assets/media/logo512svg.svg";
import { useNavigate } from "react-router-dom";
import {Button} from "@nextui-org/react";



function Header() {
  const navigate = useNavigate();

 
  return (
    <>
      <Navbar bg="" expand="lg">
        <Container id="navTop">
          <Navbar.Brand href="">
            <a href="/">
              <div className="multigradLogo">
                <img src={Logo} alt={`Multigrad App`} />
                <p>/hello@rapydlaunch.com</p>
              </div>
              </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
         
              <>
             
                <Nav className="me-auto">
              
                  <Nav.Link href="/about-us">About</Nav.Link>
                  <Nav.Link href="/portfolio">Portfolio</Nav.Link>
                  <Nav.Link href="/blogs">Blogs</Nav.Link>
                  <Nav.Link href="/career">Careers <span id="premouim">We are hiring</span></Nav.Link>
                </Nav>
                 
                  <a id="signin" href="contact-us">
                  + Get in touch</a>

                  <div className="flex gap-4 items-center">
    
      
    </div>
              </>   


          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Header;
