import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Brand from "../assets/media/brand.svg";
function Team() {

 const handleButtonClick = () => {
    const emailAddress = 'rapydlaunch@gmail.com';
    const subject = 'Let\'s Connect | Developement Inquiry';
  
    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}`;

    window.location.href = mailtoUrl;
  };
  const [isScrolled, setIsScrolled] = useState(false);



  const handleScrollClick = () => {
    const scrollOffset = 500;

    window.scrollTo({
      top: scrollOffset,
      behavior: 'smooth',
    });
  };

  return (
    <Container>
<div className="who">
<div className="rl-child">
            <h4>~Who we are</h4>
            <h2>We'r the top rated agency.</h2>
            <p>Where we are dedicated to delivering high-quality software solutions at an unprecedented pace. Our commitment is to develop innovative and efficient software products within a 45-day timeframe.</p>
            <p>At RapydLaunch, our mission is to revolutionize the software development industry by consistently delivering cutting-edge solutions within 45 days and create a marketplace for all type of software, ensuring customer satisfaction.</p>
        </div>
        <div className="rl-child">
            <div className="trans">
              <img src={Brand} />
            </div>
          
        </div>
        </div>


        <div className="who">
<div className="rl-child-full center">
            <h4>~We don't do traditional development</h4>
            <h2>We are partners, not consultants..</h2>
            <p>We work closely with founders and their teams on every stage of the process, from corporate development to due diligence. </p>
           
            <h2>We build long-term relationships.

</h2>
            <p>With our deep domain expertise and network of more than 1,5000 top decision-makers and influencers, we connect founders with the technology ecosystem to take their companies to the next level.

</p>
           
        </div>
       
        </div>

      <div className="meet-team" id="communty">
        <h2>Meet our rapid team!</h2>
        <div className="testino">
        <h3>Founder's Office</h3>
          <a href="https://www.linkedin.com/in/amnshrma/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1695584577430.png"/>
            </div>
            <div className="test-details">
              <h2>Aman Sharma</h2>
              <p>Founder</p> 
            </div>
            
          </div>
        
          </a>

          </div>

<div className="testino">

<h3>Engineering Team</h3>
          <a href="https://www.linkedin.com/in/abhishek-yadav-b627421a0/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1695584667668.png"/>
            </div>
            <div className="test-details">
              <h2>Abhishek Yadav</h2>
              <p>Product</p> 
            </div>
          </div>
</a>
    

<a href="https://www.linkedin.com/in/aayush-shrivastava-60013a17b/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1690284864386.jpeg"/>
            </div>
            <div className="test-details">
              <h2>Aayush Shrivastav</h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>
    


        
    


 

          <a href="https://www.linkedin.com/in/siddharth-bramhecha/" target="_blank">
          <div className="testinomials ano">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1728772041586.png" />
            </div>
            <div className="test-details">
              <h2>Siddharth Bramhecha
              </h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>


  
         
          <a href="https://www.linkedin.com/in/pranjal-bartwal-383345273/" target="_blank">
          <div className="testinomials ano">
            <div className="test-image">
              <img src="  https://api.multigrad.in/open/media/?file=1728772580451.jpeg" />
            </div>
            <div className="test-details">
              <h2>Pranjal Bartwal
 
              </h2>
              <p>Developer</p> 
            </div>
          </div>
          </a>


         
          <div className="testinomials ano">
            <div className="test-image">
             
            </div>
            <div className="test-details">
              <h2>+2
 
              </h2>
              <p>Developer</p> 
            </div>
          </div>
        
          </div>


        



        <div className="testino">
        <h3>Marketing Team</h3>
        <a href="https://www.linkedin.com/in/sandeep-shakya-857014249/">
          <div className="testinomials">
            <div className="test-image">
              <img src="https://api.multigrad.in/open/media/?file=1717055990662.png"/>
            </div>
            <div className="test-details">
              <h2>Sandeep Shakya</h2>
              <p>Marketing & Sales</p> 
            </div>
          </div>
          </a>
    

          <a href="https://www.linkedin.com/in/adityaa-saini/" target="_blank">
          <div className="testinomials ano">
            <div className="test-image">
              <img src={`https://api.multigrad.in/open/media/?file=1728772174290.jpeg`} />
            </div>
            <div className="test-details">
              <h2>Aditya Saini
              </h2>
              <p>Marketing & Manager</p> 
            </div>
          </div>
          </a>
          </div>
  

      </div>






    </Container>
  );
}

export default Team;
