import React, { useState } from "react";
import Logo from "../../assets/media/fullLogo.svg";
import { useNavigate, useLocation } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();


  return (
    <>
      <div className="footerContainer">
        <div className="logofooter">
        
          <p>2024, FightAge Private Limited | U80301UP2022PTC175515 </p>
          <p>Banglore (India), Delhi (India)</p>
         
        </div>
        <div className="logofooter">
          <a href="https://twitter.com/rapydlaunch" target="_blank">Twitter</a>
          <a href="https://www.instagram.com/rapydlaunch/" target="_blank">Instagram</a>
          <a href="https://rapydlaunch.slack.com/" target="_blank">Slack</a>
          <a href="https://linkedin.com/company/rapydlaunch/" target="_blank">Linkedin</a>
        </div>
       </div>

    </>
  );
};

export default Footer;
